<template>
  <div id="adList-verifyDialog">
    <el-dialog
      title="广告操作"
      :visible.sync="show"
      :close-on-press-escape="false"
      @close="reset"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="90px">
        <el-form-item label="审核状态" prop="advtisementStatus">
          <el-radio-group v-model="form.advtisementStatus">
            <el-radio label="审核未通过">拒绝</el-radio>
            <el-radio label="审核通过">通过</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      loading: false,
      advtisementId: "",
      form: {
        advtisementStatus: "",
      },
      rules: {
        advtisementStatus: [
          { required: true, message: "请选择审核状态", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.advtisementId = "";
      this.$set(this, "form", {
        advtisementStatus: "",
      });
      this.$refs["form"].resetFields();
    },
    confirmBtn() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;

            let res = await this.$http.post("/ad/edit", {
              advtisementId: this.advtisementId,
              advtisementStatus: this.form.advtisementStatus,
            });
            if (res.code === 0) {
              this.$emit("confirm");
              this.$message.success("操作成功");
              this.show = false;
            } else {
              this.$message.error(res.msg);
            }
            this.loading = false;
          } catch (err) {
            this.$message.error(err);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
#adList-verifyDialog {
  & > .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
        .richText-btn {
          margin-right: 10px;
          color: rgb(169, 165, 165);
          cursor: pointer;
          &:hover {
            color: $main-color;
          }
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
